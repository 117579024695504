import mitt from 'mitt';
const emitter = mitt();
const gtmInitialize = (context, inject) => {
    inject('eventChannel', {
        on: emitter.on,
        off: emitter.off,
        emit: emitter.emit,
    });
};
export default gtmInitialize;
